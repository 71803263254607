import Vue from "vue";
import moment from "moment";

Vue.filter("dateFormater", function (value) {
  if (!value) {
    return "";
  }
  return moment(String(value)).format("DD-MMM-YYYY");
});

Vue.filter("timeFormatter", function (value) {
  if (!value) {
    return "";
  }
  return moment(String(value)).format("LT");
});

Vue.filter("shortName", function (value) {
  if (value) {
    let name = value.split(" ");
    let shortName = "";
    for (const element of name) {
      if (element) shortName += element[0].toUpperCase();
    }
    return shortName;
  }
});

Vue.filter("doubleDigit", function (value) {
  if (value) {
    let convertedValue = parseInt(value);
    if (convertedValue < 10 && convertedValue != 0) {
      convertedValue = "0" + convertedValue;
    }
    return convertedValue;
  }
});
