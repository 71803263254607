<template>
  <div class="container-fluid footer-con d-flex" :class="{
    'my-pro-ft-mr': selectedMenu.main == 3 && selectedMenu.subMenu == 2
  }">
    <div class="footer-sub-con">
      <span class="footer-title termscon">&copy; {{ new Date().getFullYear() }} The Goodyear Tire & Rubber
        Company</span>
      |
      <span class="footer-terms">Terms & Conditions and Privacy Policy</span>
    </div>
    <div class="ft-12 footer-sub-con footer-logo-con">
      <img alt="Logo" src="./../assets/logo-footer.svg" />
    </div>
  </div>
</template>
<script>
import customTokenCredential from "../CustomTokenProvider";
//import { BlobServiceClient } from "@azure/storage-blob";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { mapGetters, mapMutations } from "vuex";
const storageAccountName = "cmatskasbackup";
export default {
  name: "Footer",
  computed: {
    ...mapGetters(["selectedMenu", "accessToken", "stateMSAL"])
  },
  data() {
    return {
      account: undefined,
      containers: []
    };
  },
  async created() {
    this.$emitter.on(
      "login",
      async function (account) {
        this.account = account;
        //await this.getAzureStorageData();
      }.bind(this)
    );
    this.$emitter.on("logout", () => {
      this.account = undefined;
    });

    setInterval(() => {
      this.getAccessToken();
    }, 300000);
  },
  methods: {
    ...mapMutations(["setAccessToken"]),
    jsonConcat(o1, o2) {
      for (let key in o2) {
        o1[key] = o2[key];
      }
      return o1;
    },
    parseJwt(token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    // async getAzureStorageData() {
    //   let tokenCredential = new customTokenCredential(this.$store.state.accessToken);
    //   const blobClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net`, tokenCredential);

    //   const iter = blobClient.listContainers();
    //   let containerItem = await iter.next();
    //   while (!containerItem.done) {
    //     this.containers.push({
    //       id: containerItem.value.properties.etag,
    //       name: containerItem.value.name
    //     });
    //     containerItem = await iter.next();
    //   }
    // },
    async getAccessTokenx() {
      let request = {
        scopes: ["https://storage.azure.com/user_impersonation"]
      };
      const msalInstance = new PublicClientApplication(this.$store.getters.stateMSAL.msalConfig);
      try {
        let tokenResponse = await msalInstance.acquireTokenSilent(request);
        this.$store.commit("setAccessToken", tokenResponse.accessToken);
      } catch (error) {
        console.error("Silent token acquisition failed. Using interactive mode");
        let tokenResponse = await msalInstance.acquireTokenPopup(request);
        this.$store.dispatch("accessToken", tokenResponse.accessToken);
        this.$store.commit("setAccessToken", tokenResponse.accessToken);
      }
    },
    async getAccessToken() {
      const msalInstance = new PublicClientApplication(this.$store.getters.stateMSAL.msalConfig);
      const redirectResponse = await msalInstance.handleRedirectPromise();
      if (redirectResponse !== null && redirectResponse) {
        let accessToken = redirectResponse.accessToken;
        this.$store.dispatch("accessToken", accessToken);
      } else {
        // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
        const account = msalInstance.getAllAccounts()[0];

        const accessTokenRequest = {
          scopes: ["user.read"],
          account: account
        };

        msalInstance
          .acquireTokenSilent(accessTokenRequest)
          .then(this.handleResponse)
          .catch(function (error) {
            //Acquire token silent failure, and send an interactive request
            //console.log(error);
            if (error instanceof InteractionRequiredAuthError) {
              msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
          });
      }
    },
    handleResponse(accessTokenRequest) {
      if (accessTokenRequest !== null && accessTokenRequest) {
        // Acquire token silent success
        // Call API with token
        // let accessToken = accessTokenRequest.idToken;
        let response = accessTokenRequest;
        // Call your API with token
        if (response && response.idToken) {
          let idToken = this.parseJwt(response.idToken);
          let accessToken = this.parseJwt(response.accessToken);
          let userProfile = idToken;
          userProfile = this.jsonConcat(userProfile, accessToken);
          userProfile = this.jsonConcat(userProfile, {
            isAuthenticated: true,
            id_token: response.idToken,
            access_token: response.accessToken
          });
          this.$oidc.userProfile = userProfile;
          this.$oidc.isAuthenticated = true;
          sessionStorage.setItem("userProfile", JSON.stringify(this.$oidc.userProfile));
          this.$store.dispatch("accessToken", accessToken);
          this.$store.commit("setAccessToken", accessToken);
        }
      }
    }
  }
};
</script>
<style scoped>
.footer-con {
  background: #f1f2f4 0% 0% no-repeat padding-box;
  border: 1px solid #e5e6e8;
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 4em;
  color: #274ba7;
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.footer-sub-con {
  width: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.footer-logo-con {
  text-align: right;
}

.termscon {
  font-weight: 600;
}

.my-pro-ft-mr {
  margin-top: 13rem !important;
}
</style>
