<template>
  <div id="app">
    <MainComponent v-if="$store.state.accessToken" />
  </div>
</template>
<script>
import MainComponent from "./MainComponent.vue";
import { PublicClientApplication } from "@azure/msal-browser";
export default {
  name: "App",
  components: {
    MainComponent
  },
  data() {
    return {
      account: undefined
    };
  },
  beforeCreate() {
    if (!sessionStorage || !sessionStorage.getItem("userProfile")) {
      localStorage.clear();
    }
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(this.$store.getters.stateMSAL.msalConfig);
    this.SignIn();
  },
  beforeMount() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit("login", this.account);
  },
  async mounted() { },
  methods: {
    SignIn() {
      this.$msalInstance.handleRedirectPromise().then(this.handleResponse);
    },
    handleResponse(response) {
      if (response !== null && response) {
        // Acquire token silent success
        let accessToken = response.accessToken;
        this.$store.dispatch("accessToken", accessToken);
        this.$store.commit("setAccessToken", response.accessToken);
        // Call your API with token
        // callApi(accessToken);
      }
      let accountId = "";
      if (response !== null) {
        accountId = response.account.homeAccountId;
        // Display signed-in user content, call API, etc.
      } else {
        // In case multiple accounts exist, you can select
        const currentAccounts = this.$msalInstance.getAllAccounts();
        if (currentAccounts.length === 0) {
          // no accounts signed-in, attempt to sign a user in
          this.$msalInstance.loginRedirect({});
        } else if (currentAccounts.length > 1) {
          // Add choose account code here
        } else if (currentAccounts.length === 1) {
          accountId = currentAccounts[0].homeAccountId;
        }
      }
      if (response && response.idToken) {
        let idToken = this.parseJwt(response.idToken);
        let accessToken = this.parseJwt(response.accessToken);
        let userProfile = idToken;
        userProfile = this.jsonConcat(userProfile, accessToken);
        userProfile = this.jsonConcat(userProfile, {
          isAuthenticated: true,
          id_token: response.idToken,
          access_token: response.accessToken
        });
        this.$oidc.userProfile = userProfile;
        this.$oidc.isAuthenticated = true;
        sessionStorage.setItem("userProfile", JSON.stringify(this.$oidc.userProfile));

      }
    },
    jsonConcat(o1, o2) {
      for (let key in o2) {
        o1[key] = o2[key];
      }
      return o1;
    },
    parseJwt(token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async SignIns() {
      await this.$msalInstance
        .loginRedirect({})
        .then((q) => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          this.$emitter.emit("login", this.account);
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      await this.$msalInstance
        .logout({})
        .then(() => {
          this.$emitter.emit("logout", "logging out");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
};
</script>
<style lang="scss">
#app {
  height: 100vh;
  font-family: "Barlow", sans-serif;
  background-color: #e5e6e8;
}

a {
  &.router-link-exact-active {
    color: #42b983;
  }
}
</style>
