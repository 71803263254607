<template>
  <div id="app">
    <Header v-if="$oidc && $oidc.userProfile && $oidc.userProfile.isAuthenticated" />
    <router-view v-if="$oidc && $oidc.userProfile && $oidc.userProfile.isAuthenticated" />
    <Footer v-if="$oidc && $oidc.userProfile && $oidc.userProfile.isAuthenticated" />
  </div>
</template>
<style>
@import url(./common/css/font-categories.css);
@import url(./common/css/CommonStyle.css);
</style>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Vue from "vue";
import "./common/css/CommonStyle.css";
import "./common/css/font-categories.css";
import "./plugins/bootstrap-vue";
import '@babel/polyfill';
import { IconsPlugin } from "bootstrap-vue";
import "../src/common/filters/filters";
Vue.use(IconsPlugin);
export default {
  name: "MainComponent",
  components: {
    Header,
    Footer,
  },
  created() {
    this.$oidc.userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  },
  beforeMount() {
    this.$oidc.userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
    if (this.$oidc.userProfile) {
      this.$oidc.isAuthenticated = true;
    } else {
      this.$oidc.isAuthenticated = false;
    }
  },
  mounted() {
    this.$oidc.userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
    if (this.$oidc.userProfile) {
      this.$oidc.isAuthenticated = true;
    } else {
      this.$oidc.isAuthenticated = false;
    }
    this.$router.push("/dashboard");
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Barlow", sans-serif;
  background-color: #e5e6e8;
}

a {
  &.router-link-exact-active {
    color: #42b983;
  }
}
</style>
