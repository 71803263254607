const ACTIONITEMS = "/Dashboard/GetActionItems";
const PRINCIPLELIST = "/MaturityAssesment/GetPrincipleList/";
const PRACTISEDETAILS = "/MaturityAssesment/GetAssesmentDetails/";
const SUBMITPRACTISEDETAILS = "/MaturityAssesment/AddAssesment/";
const GETPLANTLIST = "MaturityAssesment/GetPlantList";
const PLANTDETAILS = "MaturityAssesment/GetPlantAssesmentSummary/";
const PILLARLIST = "MaturityAssesment/GetPillarList/";
const PRINCIPLEWISESTATUS = "MaturityAssesment/GetPrincipleLevelCompletionStatus/";
const MATURITYSCORE = "MaturityAssesment/PillarLevelMaturityScore/";
const PLANTPILLARDETAILS = "MaturityAssesment/GetPillarLevelMaturityStatus/";
const TARGETACTIONDETAILS = "MaturityTargetSetting/GetActions/";
const TARGETCOMMITTED = "MaturityTargetSetting/GetCommittedActions/";
const TARGETSETTINGSUMMARY = "MaturityTargetSetting/GetTargetSettingSummary/";
const TARGETCOMPLETIONDATESET = "MaturityTargetSetting/UpdateTargetSettingCompletionDate/";
const TARGETCOMPLETIONDATEGET = "MaturityTargetSetting/GetTargetSettingCompletionDate/";
const TARGETSETACTIONS = "MaturityTargetSetting/UpdateTargetActionsCount/";
const GETACTIONITEM = "ActionTracker/GetActionItemByPlantAndPillarId/";
const GETACTIONITEMALLPILLARS = "ActionTracker/GetActionItemByPlantAllPillars/";
const ACTIONDETAILS = "/MaturityTargetSetting/GetActions/";
const TARGETSUMMARY = "/MaturityTargetSetting/GetTargetSettingSummary/";
const UPDATEDISTRIBUTEACTION = "/MaturityTargetSetting/UpdateDistributeActions";
const UPDATETARGETACTIONCOUNT = "MaturityTargetSetting/UpdateTargetActionsCount/";
const GETOWNERLIST = "UserProfile/GetUserDropdownList";
const GETVIEWACTIONITEMS = "ActionTracker/GetViewActionItems/";
const GETCOUNTSUMMARY = "ActionTracker/GetActionCountSummary/";
const GETCOUNTSUMMARYALLPILLAR = "ActionTracker/GetActionCountSummaryAllPillar/";
const GETACTIONITEMPLANTANDPRINCIPLEID = "ActionTracker/GetActionItemByPlantAndPrincipleId/";
const CREATEDOCUMENT = "/MaturityAssesment/CreateDocument/";
const CREATEADDACTION = "/ActionTracker/AddAction";
const TARGETSETINGAPPROVALGET = "MaturityTargetSetting/GetPlantPillarTargetApproval/";
const TARGETSETINGAPPROVALSET = "MaturityTargetSetting/UpdatePlantPillarTargetApproval/";
const USERPROFILECOMBINED = "UserProfile​/UserProfileCombinedDropdown";
const MANAGERLISTDROPDOWN = "UserProfile/ManagerListDropdown";
const TAKEASSESMENTAPPROVALGET = "TakeAssessment/GetApprovalStatusForAssessment/"; //GetApprovalStatusForAssessment
const TAKEASSESSMENTSECONDPARTYAPPROVAL = "TakeAssessment/AddSecondPartyValidation";
const TAKEASSESSMENTSELFASSESSMENT = "TakeAssessment/AddSelfAssessment";
const CERTIFYPLANT = "MaturityAssesment/CertifyByPlant/";
const GETPLANTPILLARASSESSMENTFORDOWNLOAD = "MaturityAssesment/GetPlantPillarAssessmentForDownload/";
const CERTIFYPILLAR = "MaturityAssesment/CertifyByPillar/";
const ActionsCountForAssessment = "TakeAssessment/GetActionsCountForAssessment/"; //TakeAssessment​/GetActionsCountForAssessment​/
const GETLISTOFUSERS = "UserProfile/GetUserTableList";
const GETMASTERDATA = "UserProfile/UserProfileCombinedDropdown";
const UPDATEACTION = "/ActionTracker/UpdateAction";
const DELETEACTION = "/ActionTracker/DeleteAction";
const MYPROFILE = "/UserProfile/GetUserProfileById1?UserEmail=";
const PROFILEHIRARCHY = "UserProfile/GetUserProfileHierarchyV2/";
const EDITUSER = "/UserProfile/EditUser";
const EDITOTHERUSER = "/UserProfile/EditOtherUser";
const GETUNCONFIGUREDUSERS = "/UserProfile/GetUnconfiguredUsers";
const USERPROFILEPLANTDROPDOWN = "/UserProfile/GetPlantDropdownList/";
const CONFIGUREANDEDITUSER = "/UserProfile/ConfigureAndEditUser";
const DELETEUSERROLE = "/UserProfile/DeleteUserRoleByUserRoleId/";
const DASHBOARDACTIONITEMS = "/Dashboard/GetActionItems1?pageNumber=";
const POACTIONITEMS = "/Dashboard/GetPOActionItems";
const GAPCLOSURE = "/Dashboard/GetGapClosure1";
const DASHBOARDACTIONSTATUS = "/Dashboard/GetActionStatusList/";
const GETGOVERNANCEDATESETTING = "/Governance/GetDateSettingData";
const ADDGOVERNANCEDATESETTING = "/Governance/AddGovernanceDateSetting";
const EDITPROFILEROLE = "/UserProfile/GetUserProfileById1?UserEmail=";
const GETOTHERDOCUMENTS_PO = "ManagePoDocument/GetOtherDocuments/";
const GETALLPRACTICEDOCUMENTSBYPRINCIPLE_PO = "ManagePoDocument/GetAllPracticeDocumentsByPrinciple/";
const ADDOTHERPODOCUMENT = "ManagePoDocument/AddOtherPODocument";
const ADDPRACTICEPODOCUMENT = "ManagePoDocument/AddPracticePODocument";
const DELETEPODOCUMENT = "ManagePoDocument/DeletePODocument";
const GETPILLARDROPDOWNLIST = "UserProfile/GetPillarDropdownList";
const GETSUBMITREQSTATUS = "Governance/GetSubmitRequestStatus";
const GETPILLARWISEREQCOUNT = "Governance/GetPillarWiseReqCount";
const GETPILLARWISEREQCOUNTHISTORY = "Governance/GetHistoryRequestCountByPillar";
const GETREQUESTDETAILSBYPILLAR = "Governance/GetRequestDetailsByPillarList";
const GETREQUESTHISDETAILSBYPILLAR = "Governance/GetRequestHistoryDetailsByPillar";
const GETCHANGETYPEDROPDOWN = "Governance/GetChangeTypeDropdown";
const ADDCONSOLIDATEDREQUEST = "Governance/AddConsolidateGovernanceRequest";
const GETRECOMMENDATIONS = "Governance/GetConsolidatedViewRequest";
const ADDNEWGOVERNANCEREQUEST = "Governance/AddNewGovernanceRequest";
const GETGAPCLOSUREREPORTS = "Report/GetGapClosureReport";
//const GETGAPCLOSUREREPORTS = "Report/GetGapClosureReport2";
//const GETGAPCLOSUREREPORTS = "Report/GetGapClosureReport3";
const GETVIEWREQUEST = "/Governance/GetViewRequest/";
const DELETEPROFILE = "/UserProfile/DeleteProfilePicture?UserEmail=";
const GETCONSOLIDATEDACTIONTRACKERREPORT = "Report/GetConsolidatedActionTrackerReport";
const GETSPIDERDATA = "Report/GetAllItemsForSpiderChartReport?";
const GETBOTTLEPRACTICEDATA = "Report/GetAllItemsForBottleNeckPracticeReport";
const UPDATEPRACTICE = "Governance/EditRequestHistory/";
const LEARNINGASSIGNMENT = "/Education/GetLearningAssignmentDashboard/";
const GETASSIGNPLANTVALIDATION = "Governance/GetAssignPlantValidationAndStatusDataTable";
const GETREGIONLIST = "Governance/GetRegions";
const GETPLANTLISTACCORDINGREGION = "Governance/PlantListAccordingRegion";
const ASSIGNPLANTFORVALIDATION = "Governance/AssignPlantValidationToRequests";
const GETREQUESTGLOBALCOUNT = "Governance/ReviewRequestGlobalCount";
const REVIEWDECISION = "Governance/GetChangeTypeDropdownForValidationRequest";
const PLANTPOVALIDATIONHISTORY = "Governance/PlantPoValidationHistoryList";
const PLANTPOVALIDATIONHISTORYPILLARCOUNT = "Governance/GetValidationHistoryRequestCountByPillarPlant/";
const PLANTPOVALIDATIONCOUNT = "Governance/GetValidationRequestCountByPillarPlant/";
const PLANTPOVALIDATIONDATA = "Governance/PlantPoValidationIncommingRequestList/";
const PLANTPOVALIDATIONPROPOSED = "Governance/GetOriginalAndProposedPracticeDetails/";
const PLATVALIDATIONSUBMIT = "Governance/PlantPoValidateRequest/";
const GLOVALPILLARVALIDATIONLIST = "Governance/GlobalPillarValidationRequestList/";
const GLOVALPILLARVALIDATIONFINALLIST = "Governance/GlobalPillarFinalAssessmentChanges/";
const GETREVIEWPRACTICESTSTATUSDATA = "Governance/GetReviewPracticeStatusData";
const GETOGANDPPDETAILS = "Governance/GetOriginalAndProposedPracticeDetails/";
const GETORIGINALPROPOSED = "Governance/GetOriginalAndProposedPracticeDetails/";
const SAVEORIGINALPROPOSED = "/Governance/SaveOriginalAndProposedPracticeDetails/";
const FEEDBACKFROMPLANTVALIDATION = "Governance/GetFeedbackDataPillarByPlant/";
const SENDFORFINALREVIEW = "/Governance/SaveFinalAssessmentAndPublishData/";
const SAVEREQDETAILS = "Governance/SaveRequestDetails/";
const MARKASREVIEWED = "Governance/MarkPracticeAsReviewed/";
const GETVALIDATIONHSTREQCNTBYGLOBAL = "Governance/GetValidationHistoryRequestCountByGlobal";
const GETVALIDATIONREQCNTBYGLOBAL = "Governance/GetValidationRequestCountByGlobal";
const PUBLISHASSESSMENT = "Governance/PublishFinalAssessmentData";
const GETLOGGEDINUSERDATA = "UserProfile/GetUserPlantAndRegionDetails";
const USERLEARNINGPROGRESS = "Education/UserLearningProgressData";
const GETASSIGNEDLEARNGSBYEMAIL = "Education/GetLearningAssignmentByEmail/";
const SEARCHALLTRAININGANDCOURSES = "Education/SearchAllTrainingsAndCourses";
const ALLPOCOURSESLIST = "Education/AllPOCoursesList";
const ADDCOURSEANDLEARNINGTOSELF = "Education/AddCourseAndLearningAssignmentsToSelf";
const GETMDETAILSFORLU = "Education/GetTeamMembersDetailsForLoggedInUser";
const ADDCOURSESTOMYTEAM = "Education/AddCourseAndLearningAssignmentsToMyTeam";
const REMOVECOURSESOFTEAM = "Education/RemoveCourseAndLearningAssignmentsForTeam";
const GETLMSUSERTABLELIST = "Education/GetLMSUserTableList";
const TEAMLEARNINGPROGRESSDATA = "Education/TeamLearningProgressData";
const TEAMLEARNINGTODOLIST = "Education/TeamLearningTodoList";
const ADDCOURSESTOMYTEAMV2 = "Education/AddCourseAndLearningAssignmentsToMyTeamV2";
const NOTIFICATIONLIST = "PushNotification/GetPushNotification";
const DIMISSNOTIFICATION = "PushNotification/DissmissNotification";
const DIMISSALLNOTIFICATION = "PushNotification/DismissAllNotifications";
const MRCOMMITTEDYEAR = "MachineReplication/ActionTracker/GetAllCommittedYear";
const MRMACHINESCORE = "MachineReplication/ActionTracker/ViewActionScoreMachineWise";
const MRPILLARDETAILS = "MachineReplication/ActionTracker/GetPillarDropdown";
const MRWORKCENTERALL = "MachineReplication/ActionTracker/GetAllWorkcenters";
const MRMACHINEDROPDOWN = "MachineReplication/ActionTracker/MachineDropdown";
const MRACTIONSCOREMACHINEWISE = "MachineReplication/ActionTracker/ViewActionScoreMachineWise";
const MRACTIONSCOREPILLARWISE = "MachineReplication/ActionTracker/ViewActionScorePillarWise";
const SAVECURRENTANDAUDIT = "MachineReplication/TargetSetting/SaveCurrentAndAuditScore";
const SAVEMRPOACTIONTARGET = "MachineReplication/TargetSetting/SaveMRPOActionTarget";
const UPLOADDATAEXCEL = "MachineReplication/ExcelUpload/UploadDataFromExcel";
const UPLOADDATAKPI = "MachineReplication/ExcelUpload/UploadKpiFromExcel";
const UPLOADHISTORY = "MachineReplication/ExcelUpload/GetAllMachineUploads";
const UPLOADHISTORYKPI = "MachineReplication/ExcelUpload/GetAllMachineKpiUploadHistory";
const MACHINEREGIONLIST = "MachineReplication/ExcelUpload/GetAllRegions";
const GETWORKCENTERBYPLANT = "MachineReplication/TargetSetting/GetWorcenterListByPlant";
const SAVEWORKCENTERBYPLANT = "MachineReplication/TargetSetting/SaveMrActionTargetApprovalFlow";
const APPROVETARGETSETTING = "MachineReplication/TargetSetting/GetMRPODraftTargets/";
const GETOEEDASHBOARDYEAR = "MachineReplication/OEE/GetOEECommitedYearDropdown";
const GETOEEDASHBOARD = "MachineReplication/OEE/GetOEEDashboardData/";
const SAVEOEEDASHBOARD = "MachineReplication/OEE/SaveOEEData";
const GETCONSTRAINTDETAILSFORNXTYR = "MachineReplication/OEE/GetPreviousYearConstraints";
const GETMACHINESBYOEECONSTRGROUPID = "MachineReplication/OEE/GetMachinesByOEEConstraintGroupId";
const SAVEMROEECONSTRAINTGROUP = "MachineReplication/OEE/SaveMROEEConstraintGroup";
const UPDATEMROEECONSTRAINTGROUP = "MachineReplication/OEE/UpdateOEEConstraintGroup";
const GETGROUPSTATUS = "MachineReplication/OEE/GetGroupStatusByPlant";
const GETALLCONSTRAINTTYPE = "MachineReplication/OEE/GetAllConstraintGroupTypes";
const GETMACHINESWHOSETARGETSET = "MachineReplication/ActionTracker/GetInitiateApprovalMachineTargets/";
const GETMACHINESWHOSETARGETNOTSET = "MachineReplication/TargetSetting/GetMachinesWithoutTargetSetting";
const GETOEEREPORT = "MachineReplication/OEE/GetOEEGraphReport";
const GETASSESSMENTREPORT1 = "MachineReplication/TargetSetting/GetActionTrackerGraphByMachines";
const GETASSESSMENTREPORT2 = "MachineReplication/ActionTracker/GetATReportByPillar";
const GETASSESSMENTREPORT3 = "MachineReplication/TargetSetting/GetActionTrackerGraphByWorkcenter";
const GETASSESSMENTREPORT4 = "MachineReplication/ActionTracker/AnnualRegionalPreviewReport";
const GETCONSTRAINTVALIDITY = "MachineReplication/OEE/GetConstraintValidity";
const GETALLOEEMACHINES = "MachineReplication/OEE/GetAllOEEMachines";
const GETOEEREGIONALCOUNTREPORT = "MachineReplication/OEE/GetOEERegionalViewReport";
const GETOVERALLOEE = "MachineReplication/OEE/GetOverallOeeLeastPerformer";
const GETMACHINESTATUSLIST = "MachineClassification/GetMachineStatusList";
const GETKPIOEEREPORT = "MachineReplication/KPI/GetKIPOEEReport";
const SAVEMACHINECLASSIFICATION = "MachineClassification/SaveMachineStatusDetails";
const GETSTATUSDROPDOWN = "MachineReplication/ActionTracker/GetReplicationStatusDropDown";
const GETMASTERREPORTDATA = "Report/GetGapClosureMasterReport";
const GETPERAMETERS = "EnergyLoss/EnergyLoss/GetParameters";
const GETUTILITY = "EnergyLoss/EnergyLoss/GetUtilities";
const GETCONSUMPTION = "EnergyLoss/EnergyLoss/GetConsumptions";
const GETDATATYPES = "EnergyLoss/EnergyLoss/GetDataTypes";
const GETPLANTLAYOUT = "EnergyLoss/EnergyLoss/GetPlantLayoutPreference/";
const GETPERIOD = "EnergyLoss/EnergyLoss/GetPeriod";
const GETENERGYPERIOD = "EnergyLoss/EnergyLoss/GetPeriod";
const GETENERGYCOMMITTEDYEAR = "EnergyLoss/EnergyLoss/GetCommitedYear";
const GETENERGYCALCULATORYEAR = "EnergyLoss/EnergyLoss/GetCommitedYear";
const GETSUBMISSIONSTATUS = "EnergyLoss/EnergyLoss/GetSubmitionStatus";
const SAVEDRAFTOFENERGYLOSS = "EnergyLoss/EnergyLoss/AddOrUpdateValues";
const SUBMITDRAFTOFENERGYLOSS = "EnergyLoss/EnergyLoss/SubmitValues";
const ENGERYLOSSSTATUS = "EnergyLoss/EnergyLoss/ValuesSubmissionStatus";
const GETENERGYLOSSREGIONS = "EnergyLoss/EnergyLoss/GetRegionList";
const GETENERGYLOSSPLANTS = "EnergyLoss/EnergyLoss/GetPlantList";
const GETENERGYLOSSCATEGORY = "EnergyLoss/EnergyLoss/GetProjectZeroLossCategory";
const GETENERGYLOSSSOURCE = "EnergyLoss/EnergyLoss/GetProjectSource";
const SAVEENERGYLOSSPROJECTS = "EnergyLoss/EnergyLoss/PostProject";
const GETPROJECTLISTSUMMARY = "EnergyLoss/EnergyLoss/EnteredProjectDetails";
const GETRESETSTATUS = "EnergyLoss/EnergyLoss/EnableEdit";
const ENABLESUBMISSIONBTN = "EnergyLoss/EnergyLoss/ParameterValuesStatus";
const SENDMONTHLYREPORT = "Report/SendGapClosureMontlyReportEmail";
const DELETEENERGYPROJECTREC = "EnergyLoss/EnergyLoss/RemoveProject";
const GAPCLOUSREARCHIVE = "Report/GetGapclosureArchives";
const NEWSTATUSPLANT = "EnergyLoss/EnergyLoss/GetNewStatusPlantList";
const USERPROFILEENERGYPLANT = "UserProfile/GetEnergyPlantList";
const USERPROFILEENERGYREG = "UserProfile/GetEnergyRegionList";
const PROJECTLISTYEAR = "EnergyLoss/EnergyLoss/GetProjectCreatedYear";
const GETPROCESSAREALIST = "EnergyLoss/EnergyLoss/GetProjectProcessArea";
const GETAOP = "Aop/GetAop/";
const GETAOPAPPROVALSTATUS = "Aop/GetAopApprovalStatus";
const UPDATEAOP = "Aop/AddOrUpdateAop";
const SAVEAPPROVALS = "Aop/SaveAOPApprovals";
const GETAOPYEAR = "Aop/GetAopYearDropDown";
const GETNOTSUSTAINED = "Report/GetNotSustainmentReport";
const GETALLUSERROLES = "UserProfile/GetAllUserRoles";

export default {
  GETLMSUSERTABLELIST: GETLMSUSERTABLELIST,
  TEAMLEARNINGTODOLIST: TEAMLEARNINGTODOLIST,
  GETOTHERDOCUMENTS_PO: GETOTHERDOCUMENTS_PO,
  GETALLPRACTICEDOCUMENTSBYPRINCIPLE_PO: GETALLPRACTICEDOCUMENTSBYPRINCIPLE_PO,
  ADDOTHERPODOCUMENT: ADDOTHERPODOCUMENT,
  ADDPRACTICEPODOCUMENT: ADDPRACTICEPODOCUMENT,
  DELETEPODOCUMENT: DELETEPODOCUMENT,
  GETPILLARDROPDOWNLIST: GETPILLARDROPDOWNLIST,
  ACTIONITEMS: ACTIONITEMS,
  PRINCIPLELIST: PRINCIPLELIST,
  PRACTISEDETAILS: PRACTISEDETAILS,
  SUBMITPRACTISEDETAILS: SUBMITPRACTISEDETAILS,
  GETPLANTLIST: GETPLANTLIST,
  PLANTDETAILS: PLANTDETAILS,
  PLANTPILLARDETAILS: PLANTPILLARDETAILS,
  ACTIONDETAILS: ACTIONDETAILS,
  TARGETSUMMARY: TARGETSUMMARY,
  UPDATEDISTRIBUTEACTION: UPDATEDISTRIBUTEACTION,
  PILLARLIST: PILLARLIST,
  PRINCIPLEWISESTATUS: PRINCIPLEWISESTATUS,
  MATURITYSCORE: MATURITYSCORE,
  TARGETACTIONDETAILS: TARGETACTIONDETAILS,
  TARGETCOMMITTED: TARGETCOMMITTED,
  TARGETSETTINGSUMMARY: TARGETSETTINGSUMMARY,
  TARGETCOMPLETIONDATESET: TARGETCOMPLETIONDATESET,
  TARGETCOMPLETIONDATEGET: TARGETCOMPLETIONDATEGET,
  TARGETSETACTIONS: TARGETSETACTIONS,
  GETACTIONITEM: GETACTIONITEM,
  GETACTIONITEMALLPILLARS: GETACTIONITEMALLPILLARS,
  GETVIEWACTIONITEMS: GETVIEWACTIONITEMS,
  GETCOUNTSUMMARY: GETCOUNTSUMMARY,
  GETCOUNTSUMMARYALLPILLAR: GETCOUNTSUMMARYALLPILLAR,
  GETOWNERLIST: GETOWNERLIST,
  UPDATETARGETACTIONCOUNT: UPDATETARGETACTIONCOUNT,
  CREATEDOCUMENT: CREATEDOCUMENT,
  CREATEADDACTION: CREATEADDACTION,
  TARGETSETINGAPPROVALGET: TARGETSETINGAPPROVALGET,
  TARGETSETINGAPPROVALSET: TARGETSETINGAPPROVALSET,
  GETACTIONITEMPLANTANDPRINCIPLEID: GETACTIONITEMPLANTANDPRINCIPLEID,
  USERPROFILECOMBINED: USERPROFILECOMBINED,
  TAKEASSESMENTAPPROVALGET: TAKEASSESMENTAPPROVALGET,
  TAKEASSESSMENTSECONDPARTYAPPROVAL: TAKEASSESSMENTSECONDPARTYAPPROVAL,
  TAKEASSESSMENTSELFASSESSMENT: TAKEASSESSMENTSELFASSESSMENT,
  GETPLANTPILLARASSESSMENTFORDOWNLOAD: GETPLANTPILLARASSESSMENTFORDOWNLOAD,
  CERTIFYPLANT: CERTIFYPLANT,
  CERTIFYPILLAR: CERTIFYPILLAR,
  ActionsCountForAssessment: ActionsCountForAssessment,
  GETLISTOFUSERS: GETLISTOFUSERS,
  GETMASTERDATA: GETMASTERDATA,
  UPDATEACTION: UPDATEACTION,
  DELETEACTION: DELETEACTION,
  MYPROFILE: MYPROFILE,
  PROFILEHIRARCHY: PROFILEHIRARCHY,
  EDITUSER: EDITUSER,
  EDITOTHERUSER: EDITOTHERUSER,
  USERPROFILEPLANTDROPDOWN: USERPROFILEPLANTDROPDOWN,
  POACTIONITEMS: POACTIONITEMS,
  GAPCLOSURE: GAPCLOSURE,
  DASHBOARDACTIONITEMS: DASHBOARDACTIONITEMS,
  DASHBOARDACTIONSTATUS: DASHBOARDACTIONSTATUS,
  GETGOVERNANCEDATESETTING: GETGOVERNANCEDATESETTING,
  ADDGOVERNANCEDATESETTING: ADDGOVERNANCEDATESETTING,
  EDITPROFILEROLE: EDITPROFILEROLE,
  // DASHBOARDACTIONITEMSNEXTPAGE:DASHBOARDACTIONITEMSNEXTPAGE,
  GETSUBMITREQSTATUS: GETSUBMITREQSTATUS,
  GETPILLARWISEREQCOUNT: GETPILLARWISEREQCOUNT,
  GETPILLARWISEREQCOUNTHISTORY: GETPILLARWISEREQCOUNTHISTORY,
  GETREQUESTDETAILSBYPILLAR: GETREQUESTDETAILSBYPILLAR,
  GETREQUESTHISDETAILSBYPILLAR: GETREQUESTHISDETAILSBYPILLAR,
  // DASHBOARDACTIONITEMSNEXTPAGE:DASHBOARDACTIONITEMSNEXTPAGE
  GETCHANGETYPEDROPDOWN: GETCHANGETYPEDROPDOWN,
  ADDCONSOLIDATEDREQUEST: ADDCONSOLIDATEDREQUEST,
  GETRECOMMENDATIONS: GETRECOMMENDATIONS,
  ADDNEWGOVERNANCEREQUEST: ADDNEWGOVERNANCEREQUEST,
  GETVIEWREQUEST: GETVIEWREQUEST,
  GETGAPCLOSUREREPORTS: GETGAPCLOSUREREPORTS,
  DELETEPROFILE: DELETEPROFILE,
  GETCONSOLIDATEDACTIONTRACKERREPORT: GETCONSOLIDATEDACTIONTRACKERREPORT,
  GETSPIDERDATA: GETSPIDERDATA,
  GETBOTTLEPRACTICEDATA: GETBOTTLEPRACTICEDATA,
  UPDATEPRACTICE: UPDATEPRACTICE,
  LEARNINGASSIGNMENT: LEARNINGASSIGNMENT,
  REVIEWDECISION: REVIEWDECISION,
  PLANTPOVALIDATIONHISTORY: PLANTPOVALIDATIONHISTORY,
  PLANTPOVALIDATIONHISTORYPILLARCOUNT: PLANTPOVALIDATIONHISTORYPILLARCOUNT,
  PLANTPOVALIDATIONCOUNT: PLANTPOVALIDATIONCOUNT,
  PLANTPOVALIDATIONDATA: PLANTPOVALIDATIONDATA,
  PLANTPOVALIDATIONPROPOSED: PLANTPOVALIDATIONPROPOSED,
  PLATVALIDATIONSUBMIT: PLATVALIDATIONSUBMIT,
  GLOVALPILLARVALIDATIONLIST: GLOVALPILLARVALIDATIONLIST,
  GLOVALPILLARVALIDATIONFINALLIST: GLOVALPILLARVALIDATIONFINALLIST,
  GETREVIEWPRACTICESTSTATUSDATA: GETREVIEWPRACTICESTSTATUSDATA,
  // GETREVIEWPRACTICESTSTATUSDATA:GETREVIEWPRACTICESTSTATUSDATA,
  GETOGANDPPDETAILS: GETOGANDPPDETAILS,
  FEEDBACKFROMPLANTVALIDATION: FEEDBACKFROMPLANTVALIDATION,
  GETORIGINALPROPOSED: GETORIGINALPROPOSED,
  SAVEORIGINALPROPOSED: SAVEORIGINALPROPOSED,
  SENDFORFINALREVIEW: SENDFORFINALREVIEW,
  SAVEREQDETAILS: SAVEREQDETAILS,
  GETASSIGNPLANTVALIDATION: GETASSIGNPLANTVALIDATION,
  GETREGIONLIST: GETREGIONLIST,
  GETPLANTLISTACCORDINGREGION: GETPLANTLISTACCORDINGREGION,
  ASSIGNPLANTFORVALIDATION: ASSIGNPLANTFORVALIDATION,
  GETREQUESTGLOBALCOUNT: GETREQUESTGLOBALCOUNT,
  MARKASREVIEWED: MARKASREVIEWED,
  GETVALIDATIONHSTREQCNTBYGLOBAL: GETVALIDATIONHSTREQCNTBYGLOBAL,
  GETVALIDATIONREQCNTBYGLOBAL: GETVALIDATIONREQCNTBYGLOBAL,
  PUBLISHASSESSMENT: PUBLISHASSESSMENT,
  GETLOGGEDINUSERDATA: GETLOGGEDINUSERDATA,
  USERLEARNINGPROGRESS: USERLEARNINGPROGRESS,
  GETASSIGNEDLEARNGSBYEMAIL: GETASSIGNEDLEARNGSBYEMAIL,
  SEARCHALLTRAININGANDCOURSES: SEARCHALLTRAININGANDCOURSES,
  ALLPOCOURSESLIST: ALLPOCOURSESLIST,
  ADDCOURSEANDLEARNINGTOSELF: ADDCOURSEANDLEARNINGTOSELF,
  GETMDETAILSFORLU: GETMDETAILSFORLU,
  ADDCOURSESTOMYTEAM: ADDCOURSESTOMYTEAM,
  ADDCOURSESTOMYTEAMV2: ADDCOURSESTOMYTEAMV2,
  REMOVECOURSESOFTEAM: REMOVECOURSESOFTEAM,
  TEAMLEARNINGPROGRESSDATA: TEAMLEARNINGPROGRESSDATA,
  NOTIFICATIONLIST: NOTIFICATIONLIST,
  DIMISSNOTIFICATION: DIMISSNOTIFICATION,
  DIMISSALLNOTIFICATION: DIMISSALLNOTIFICATION,
  GETUNCONFIGUREDUSERS: GETUNCONFIGUREDUSERS,
  MANAGERLISTDROPDOWN: MANAGERLISTDROPDOWN,
  CONFIGUREANDEDITUSER: CONFIGUREANDEDITUSER,
  DELETEUSERROLE: DELETEUSERROLE,
  MRCOMMITTEDYEAR: MRCOMMITTEDYEAR,
  MRMACHINESCORE: MRMACHINESCORE,
  MRPILLARDETAILS: MRPILLARDETAILS,
  MRWORKCENTERALL: MRWORKCENTERALL,
  MRMACHINEDROPDOWN: MRMACHINEDROPDOWN,
  MRACTIONSCOREMACHINEWISE: MRACTIONSCOREMACHINEWISE,
  MRACTIONSCOREPILLARWISE: MRACTIONSCOREPILLARWISE,
  SAVECURRENTANDAUDIT: SAVECURRENTANDAUDIT,
  SAVEMRPOACTIONTARGET: SAVEMRPOACTIONTARGET,
  UPLOADDATAEXCEL: UPLOADDATAEXCEL,
  UPLOADDATAKPI: UPLOADDATAKPI,
  UPLOADHISTORY: UPLOADHISTORY,
  UPLOADHISTORYKPI: UPLOADHISTORYKPI,
  MACHINEREGIONLIST: MACHINEREGIONLIST,
  GETWORKCENTERBYPLANT: GETWORKCENTERBYPLANT,
  SAVEWORKCENTERBYPLANT: SAVEWORKCENTERBYPLANT,
  APPROVETARGETSETTING: APPROVETARGETSETTING,
  GETOEEDASHBOARDYEAR: GETOEEDASHBOARDYEAR,
  GETOEEDASHBOARD: GETOEEDASHBOARD,
  SAVEOEEDASHBOARD: SAVEOEEDASHBOARD,
  GETCONSTRAINTDETAILSFORNXTYR: GETCONSTRAINTDETAILSFORNXTYR,
  GETMACHINESBYOEECONSTRGROUPID: GETMACHINESBYOEECONSTRGROUPID,
  SAVEMROEECONSTRAINTGROUP: SAVEMROEECONSTRAINTGROUP,
  UPDATEMROEECONSTRAINTGROUP: UPDATEMROEECONSTRAINTGROUP,
  GETGROUPSTATUS: GETGROUPSTATUS,
  GETALLCONSTRAINTTYPE: GETALLCONSTRAINTTYPE,
  GETMACHINESWHOSETARGETSET: GETMACHINESWHOSETARGETSET,
  GETMACHINESWHOSETARGETNOTSET: GETMACHINESWHOSETARGETNOTSET,
  GETOEEREPORT: GETOEEREPORT,
  GETASSESSMENTREPORT1: GETASSESSMENTREPORT1,
  GETASSESSMENTREPORT2: GETASSESSMENTREPORT2,
  GETASSESSMENTREPORT3: GETASSESSMENTREPORT3,
  GETASSESSMENTREPORT4: GETASSESSMENTREPORT4,
  GETCONSTRAINTVALIDITY: GETCONSTRAINTVALIDITY,
  GETALLOEEMACHINES: GETALLOEEMACHINES,
  GETOEEREGIONALCOUNTREPORT: GETOEEREGIONALCOUNTREPORT,
  GETOVERALLOEE: GETOVERALLOEE,
  GETKPIOEEREPORT: GETKPIOEEREPORT,
  GETMACHINESTATUSLIST: GETMACHINESTATUSLIST,
  SAVEMACHINECLASSIFICATION: SAVEMACHINECLASSIFICATION,
  GETSTATUSDROPDOWN: GETSTATUSDROPDOWN,
  // GETREGIONLIST:GETREGIONLIST,
  GETMASTERREPORTDATA: GETMASTERREPORTDATA,
  GETUTILITY: GETUTILITY,
  GETCONSUMPTION: GETCONSUMPTION,
  GETDATATYPES: GETDATATYPES,
  GETPLANTLAYOUT: GETPLANTLAYOUT,
  GETPERIOD: GETPERIOD,
  GETPERAMETERS: GETPERAMETERS,
  GETENERGYCOMMITTEDYEAR: GETENERGYCOMMITTEDYEAR,
  GETENERGYCALCULATORYEAR: GETENERGYCALCULATORYEAR,
  GETENERGYPERIOD: GETENERGYPERIOD,
  GETSUBMISSIONSTATUS: GETSUBMISSIONSTATUS,
  SAVEDRAFTOFENERGYLOSS: SAVEDRAFTOFENERGYLOSS,
  SUBMITDRAFTOFENERGYLOSS: SUBMITDRAFTOFENERGYLOSS,
  ENGERYLOSSSTATUS: ENGERYLOSSSTATUS,
  GETENERGYLOSSREGIONS: GETENERGYLOSSREGIONS,
  GETENERGYLOSSPLANTS: GETENERGYLOSSPLANTS,
  GETENERGYLOSSCATEGORY: GETENERGYLOSSCATEGORY,
  GETENERGYLOSSSOURCE: GETENERGYLOSSSOURCE,
  SAVEENERGYLOSSPROJECTS: SAVEENERGYLOSSPROJECTS,
  GETPROJECTLISTSUMMARY: GETPROJECTLISTSUMMARY,
  GETRESETSTATUS: GETRESETSTATUS,
  ENABLESUBMISSIONBTN: ENABLESUBMISSIONBTN,
  SENDMONTHLYREPORT: SENDMONTHLYREPORT,
  DELETEENERGYPROJECTREC: DELETEENERGYPROJECTREC,
  GAPCLOUSREARCHIVE: GAPCLOUSREARCHIVE,
  NEWSTATUSPLANT: NEWSTATUSPLANT,
  USERPROFILEENERGYPLANT: USERPROFILEENERGYPLANT,
  USERPROFILEENERGYREG: USERPROFILEENERGYREG,
  PROJECTLISTYEAR: PROJECTLISTYEAR,
  GETAOP: GETAOP,
  UPDATEAOP: UPDATEAOP,
  SAVEAPPROVALS: SAVEAPPROVALS,
  GETAOPAPPROVALSTATUS: GETAOPAPPROVALSTATUS,
  GETAOPYEAR: GETAOPYEAR,
  GETPROCESSAREALIST: GETPROCESSAREALIST,
  GETNOTSUSTAINED: GETNOTSUSTAINED,
  GETALLUSERROLES: GETALLUSERROLES
};
