import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",

    component: () => import("../views/Dashboard/Dashboard.vue")
  },
  {
    path: "/dashboard",
    name: "dashboard",

    component: () => import("../views/Dashboard/Dashboard.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../components/Login.vue")
  },
  {
    path: "/assessment",
    name: "assessment",

    component: () => import("../views/MaturityModel/Assessment/Assessment.vue")
  },
  {
    path: "/take-assessment/:id/:ids/:pillarName/:screen/:plantName/:levelId/:principle",
    // path: '/take-assessment/:id/:ids/:pillarName/:screen/:plantName/',
    name: "take-assessment",
    component: () => import("../views/MaturityModel/Assessment/TakeAssessment.vue"),
    props: true
  },
  {
    path: "/add-new-action/:plantId/:pillarId/:principleId/:practiceId/:actionID/:levelId/:pillarName/:plantName/:statusId/:name",
    name: "add-new-action",
    component: () => import("../views/MaturityModel/Assessment/AddNewAction.vue")
  },
  // {
  //   path: '/edit-action/:actionDesc/:actionTargetDate/:actionCompleteDate/:actionOwnerName',
  //   name: 'edit-action',
  //   component: () => import('../views/MaturityModel/Assessment/EditActionButton.vue')
  // },
  {
    path: "/view-assessment/:plantId/:pillarId/:plantName",
    name: "view-assessment",
    component: () => import("../views/MaturityModel/Assessment/ViewAssessment.vue")
  },
  {
    path: "/forecast-setting",
    name: "forecast-setting",
    component: () => import("../views/MaturityModel/TargetSetting/TargetSetting.vue")
  },
  {
    path: "/aop-target",
    name: "aop-target",
    component: () => import("../views/MaturityModel/AOPTarget/AOPTarget.vue")
  },
  {
    path: "/edit-target/:id",
    name: "edit-target",
    component: () => import("../views/MaturityModel/TargetSetting/EditTarget.vue")
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("../views/Unauthorized.vue")
  },

  {
    path: "/edit-target",
    name: "edit-target",
    component: () => import("../views/MaturityModel/TargetSetting/EditTarget.vue")
  },
  {
    path: "/profile",
    name: "profile",

    component: () => import("../views/Profile/Profile.vue")
  },
  {
    path: "/edit-user-home",
    name: "edit-user-home",

    component: () => import("../views/Profile/EditUserHome.vue")
  },
  {
    path: "/edit-user/:id",
    name: "edit-user",

    component: () => import("../views/Profile/EditUser.vue")
  },
  {
    path: "/configure-profile",
    name: "configure-profile",

    component: () => import("../views/Profile/configureProfile.vue")
  },
  {
    path: "/configure-user/:id",
    name: "configure-user",

    component: () => import("../views/Profile/configureUser.vue")
  },
  {
    path: "/edit-my-profile",
    name: "edit-my-profile",

    component: () => import("../views/Profile/EditMyProfile.vue")
  },
  // {
  //   path: '/auth/signinwin/main',
  //   name:'auth' ,
  //   component: () => import('../views/Auth.vue')
  // } ,
  {
    path: "/action/:screen/:plantId/:pillarId",
    name: "action",
    component: () => import("../views/MaturityModel/ActionTracker/Action.vue")
  },

  // {
  //   path: '/edit-my-profile',
  //   name: 'edit-my-profile',
  //   component: () => import('../views/editprofile/EditMyProfile')
  // },
  {
    path: "/add-action/:plantid/:pillarid/:principleid/:pillarName",
    name: "add-action",
    component: () => import("../views/MaturityModel/ActionTracker/AddAction.vue")
  },
  {
    path: "/view-action/:screen/:id/:ids/:pillarId",
    name: "view-action",
    component: () => import("../views/MaturityModel/ActionTracker/ViewActionTracker.vue")
  },
  {
    path: "/governance",
    name: "governance",

    component: () => import("../views/MaturityModel/Governance/Governance.vue")
    // beforeEnter: (to, from, next) => {
    //   if(role.includes(Role.GLOBALPILLARLEADER) ||role.includes(Role.REGIONALPOLEADER) ||
    //   role.includes(Role.REGIONALPILLARLEADER) ||role.includes(Role.PLANTPOLEADER) ||
    //   role.includes(Role.PLANTPILLARLEADER) || role.includes(Role.USER)) {
    //     next()
    //   } else {
    //     next({ name: 'dashboard' })
    //   }
    // }
  },
  {
    path: "/perform-action/:plantId/:pillarId/:principleId/:level/:reqIds",
    name: "perform-action",
    component: () => import("../views/MaturityModel/Governance/MergeRequest.vue")
  },
  {
    path: "/viewRequest/:levelId/:pillarName/:principleName/:principleId/:id/:edit",
    name: "viewRequest",
    component: () => import("../views/MaturityModel/Governance/viewRequest.vue")
  },
  {
    path: "/submitRequest/:plantId",
    name: "submitRequest",

    component: () => import("../views/MaturityModel/Governance/SubmitRequest.vue")
  },
  {
    path: "/review-request/:levelId/:pillarName/:plantId/:requestId/:principleName/:pillarId/:edit",
    name: "review-request",

    component: () => import("../views/MaturityModel/Governance/ReviewRequest.vue")
  },
  {
    path: "/viewPractice/:levelId/:pillarName/:plantId/:requestId/:principleName/:principleId/:edit",
    // path: '/viewPractice/:levelId/:pillarName/:principleName',
    name: "viewPractice",

    component: () => import("../views/MaturityModel/Governance/ViewPractice.vue")
  },
  {
    path: "/requestValidation/:levelId/:pillarName/:plantId/:requestId/:principleName/:principleId/:edit",
    // path: '/viewPractice/:levelId/:pillarName/:principleName',
    name: "requestValidation",

    component: () => import("../views/MaturityModel/Governance/RequestValidation.vue")
  },
  {
    path: "/viewConsolidatedRequest/:levelId/:pillarName/:plantId/:requestId/:principleName/:principleId/:requestids",
    // path: '/viewPractice/:levelId/:pillarName/:principleName',
    // path: '/viewConsolidatedRequest',
    name: "viewConsolidatedRequest",

    component: () => import("../views/MaturityModel/Governance/viewConsolidatedRequest.vue")
  },
  {
    path: "/viewRequest/:id/:edit",
    // path: '/viewPractice/:levelId/:pillarName/:principleName',
    name: "viewRequest",

    component: () => import("../views/MaturityModel/Governance/viewRequest.vue")
  },
  {
    path: "/viewRequestAdmin",
    // path: '/viewPractice/:levelId/:pillarName/:principleName',
    name: "viewRequestAdmin",

    component: () => import("../views/MaturityModel/Governance/viewRequestAdmin.vue")
  },
  {
    path: "/assign-plant-validation",
    name: "assign-plant-validation",
    component: () => import("../views/MaturityModel/Governance/AssignPlantValidation.vue")
  },
  {
    path: "/assign-validation-req",
    name: "assign-validation-req",
    component: () => import("../views/MaturityModel/Governance/AssignValidationRequest.vue")
  },
  {
    path: "/gap-closure",
    name: "gap-closure",
    component: () => import("../views/Reports/GapClosure/GapClosureDashboard.vue")
  },
  {
    path: "/gap-closure-plant",
    name: "gap-closure-plant",
    component: () => import("../views/Reports/GapClosure/GraphEachPlant.vue")
  },
  {
    path: "/not-sustained",
    name: "not-sustained",
    component: () => import("../views/Reports/GapClosure/NotSustainedReport.vue")
  },
  {
    path: "/bottleneck",
    name: "bottleneck",
    component: () => import("../views/Reports/BottleneckPractice/BottleneckPractice.vue")
  },
  {
    path: "/action-tracker-view",
    name: "action-tracker-view",
    component: () => import("../views/Reports/ActionTrackerViewer/ActionTrackerViewer.vue")
  },
  {
    path: "/maturity-level-graph",
    name: "maturity-level-graph",
    component: () => import("../views/Reports/MaturityLevel/MaturityLevel.vue")
  },
  {
    path: "/manage-po-documents",
    name: "managePODocument",
    component: () => import("../views/Education/ManagePODocument/ManagePODocument.vue")
  },
  {
    path: "/edit-education",
    name: "edit-education",
    component: () => import("../views/Education/EditEducation.vue"),
    props: true
  },
  {
    path: "/assign-learning-to-self",
    name: "assign-learning-to-self",
    component: () => import("../views/Education/AssignLearningtoself.vue")
  },
  {
    path: "/AssignLearning",
    name: "AssignLearning",
    component: () => import("../views/Education/AssignLearning.vue")
  },
  {
    path: "/educationHomePage",
    name: "educationHomePage",
    component: () => import("../views/Education/educationHomePage.vue")
  },
  {
    path: "/review-process",
    name: "review-process",
    component: () => import("../views/MaturityModel/Governance/ReviewProgress.vue")
  },
  {
    path: "/my-team-learning",
    name: "my-team-learning",
    component: () => import("../views/Education/MyTeamLearning.vue")
  },
  {
    path: "/auth/signoutwin/main",
    name: "logout",
    component: () => import("../views/Logout.vue")
  },
  //Machine Replication

  {
    path: "/machine-assessment",
    name: "machine-assessment",
    component: () => import("../views/MachineReplication/MachineAssessment/MachineAssessment.vue")
  },
  {
    path: "/machine-action-pillar",
    name: "machine-action-pillar",
    component: () => import("../views/MachineReplication/ActionTracker/ActionTrackerPillarWise.vue")
  },
  {
    path: "/machine-action-machine",
    name: "machine-action-machine",
    component: () => import("../views/MachineReplication/ActionTracker/ActionTrackerMachineWise.vue")
  },
  {
    path: "/current-score-setting",
    name: "Current Score Setting",
    component: () => import("../views/MachineReplication/TargetCurrentSetting/CurrentScoreSetting.vue")
  },
  {
    path: "/machine-upload",
    name: "machine-upload",
    component: () => import("../views/MachineReplication/Governance/MachineLoadUpload.vue")
  },
  {
    path: "/kpi-upload",
    name: "kpi-upload",
    component: () => import("../views/MachineReplication/Governance/KPILoadUpload.vue")
  },
  {
    path: "/machine-set-target",
    name: "machine-set-target",
    component: () => import("../views/MachineReplication/TargetCurrentSetting/MachineTargetSetting.vue")
  },
  {
    path: "/machine-targetset-Approval",
    name: "machine-targetset-target",
    component: () => import("../views/MachineReplication/TargetCurrentSetting/TargetSettingApproval.vue")
  },
  {
    path: "/constraint-machines-group",
    name: "constraint-machines-group",
    component: () => import("../views/MachineReplication/OEEDashboardandGroup/ConstraintMachinesGroup.vue")
  },
  {
    path: "/edit-constraint-machines-group",
    name: "edit-constraint-machines-group",
    component: () => import("../views/MachineReplication/OEEDashboardandGroup/EditConstraintMachinesGroup.vue")
  },
  {
    path: "/machine-initiate-approval",
    name: "Machine Initiate Approval",
    component: () => import("../views/MachineReplication/TargetCurrentSetting/InitiateApprovalScreen.vue")
  },
  {
    path: "/machine-oee-report",
    name: "machine-ooe-report",
    component: () => import("../views/MachineReplication/Reports/MachineOEEReport.vue")
  },
  {
    path: "/machine-assessment-report",
    name: "machine-assessment-report",
    component: () => import("../views/MachineReplication/Reports/AssessmentReport.vue")
  },
  {
    path: "/oee-regional-report",
    name: "oee-regional-report",
    component: () => import("../views/MachineReplication/Reports/OEERegionalCountReport.vue")
  },
  {
    path: "/machine-classification",
    name: "machine-classification",
    component: () => import("../views/MachineReplication/MachineAssessment/MachineClassification.vue")
  },
  {
    path: "/machine-kpi-report",
    name: "machine-kpi-report",
    component: () => import("../views/MachineReplication/Reports/KPIReport.vue")
  },
  //Machine Replication
  // Energy Loss
  {
    path: "/energy-loss-calculator",
    name: "energy-loss-calculator",
    component: () => import("../views/EnergyLoss/EnergyLossCalculator.vue")
  },
  {
    path: "/energy-loss-project-list",
    name: "energy-loss-project-list",
    component: () => import("../views/EnergyLoss/EnergyLossProjectList.vue")
  },
  {
    path: "/energy-loss-project-summary",
    name: "energy-loss-project-summary",
    component: () => import("../views/EnergyLoss/ProjectListSummary.vue")
  },
  {
    path: "/energy-loss-submission",
    name: "energy-loss-submission",
    component: () => import("../views/EnergyLoss/EnergyLossStatus.vue")
  }
  // Energy Loss
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
export default router;
