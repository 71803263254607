<template ref="notificationModaltemplate">
  <b-modal id="notificationModal" class="cut-not" hide-footer ref="notificationModal">
    <template #modal-header="{ close }">
      <div class="filter" style="width: 97%">
        <div class="filter-header-font-style primary-color filter-name">
          Notifications
        </div>
        <div class="act-con">
          <div class="close" @click="close">
            <b-icon icon="x" aria-hidden="true"></b-icon>
          </div>
        </div>
      </div>
      <hr class="dividera" />
    </template>
    <template #default="{ close }">
      <div class="dismiss-all-con" v-if="notificationArray.length > 1">
        <b-button pill class="dismiss-all-nf" @click="clearAllNotification(close)">Dismiss All</b-button>
      </div>
      <div :class="{ 'not-mr': notificationArray.length > 1 }">
        <div class="filter-con">
          <div class="mb-3 fc-con" v-for="(item, index) in notificationArray" v-bind:key="item.id">
            <div class="close" @click="clearNotification(item, index, close)">
              <b-icon icon="x" aria-hidden="true"></b-icon>
            </div>
            <hr class="dividera" v-if="index !== 0" />
            <div @click="navigateToEditAction(item, index, close)" :class="{ marginNotification: index !== 0 }">
              <label class="font-notification-content">{{ item.message }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "Notification",
  props: {
    notificationArray: [],
    navigateToEditAction: Function,
    clearNotification: Function,
    clearAllNotification: Function
  },
  components: {},
  data() {
    return {
      selection: [],
      dataArray: {},
    };
  },

  method: {},
};
</script>
<style scoped>
.marginNotification {
  margin-top: 1.8rem;
}

.cust-ft-icon {
  background: url(../../assets/Arrow_Dropdown.svg) no-repeat 98% center;
  -webkit-appearance: none;
  padding-right: 2rem;
}

.cust-ft-icon:disabled {
  background: url(../../assets/Drop-down_disabled.svg) no-repeat 98% center;
  -webkit-appearance: none;
  padding-right: 2rem;
}

.font-style-select {
  font-size: 1.3333333333333333rem;
  font-family: "BarlowR", sans-serif;
}

.checkbox-label {
  display: inline-block;
}

.test {
  position: relative;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.filter {
  display: flex;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.filter-name {
  font-weight: 600;
  width: 50%;
}

.act-con {
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
  align-items: center;
  margin-top: -6px;
}

.close {
  color: "#E6E6E6";
}

.aplly-btn {
  background: #274ba7;
  border-color: #274ba7;
  width: 5em;
}

.filter-con {
  padding-left: 2rem;
  padding-right: 2rem;
  height: 477px;
  overflow-y: auto;
}

.filter-con .form-control {
  border: 1px solid #313131;
  height: 2.8em;
  color: #274ba7;
  font-weight: 600;
}

.form-label {
  color: #274ba7;
}

.filter-con .form-control:focus {
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

.lable-text {
  position: absolute;
  background: #fff;
  margin: -0.5rem 0 0 0.7rem;
  padding-right: 5px;
  padding-left: 5px;
}

.fc-con {
  margin-top: 1.5rem;
}

.dividera {
  margin-top: 0rem !important;
}

.dismiss-all-nf {
  background: #274ba7;
  border-color: #274ba7;
}

.dismiss-all-nf:hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.dismiss-all-nf:active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

/* select {
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: 244px;
} */
.dismiss-all-con {
  position: absolute;
  right: 0;
  padding-right: 3rem;
}

.not-mr {
  margin-top: 2rem;
}
</style>
