import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state: {
    dashboardMenus: {
      gapClosure: true,
      machineReplication: true,
      assiignedEduction: true,
      actionItems: true,
      myTeam: true,
      requiresAction: true
    },
    selectedMenu: {
      main: 0,
      subMenu: 0
    },
    breadcrumb: {
      title: "",
      breadcrumb: []
    },
    oeeSelectedMonth: new Date().getMonth(),
    oeeSelectedYear: 1,
    plantSelectedInAsmtHome: 0,
    prevSelectedWorkCenter: 0,
    pillarPrevSelected: 0,
    plantSelectedInTargetHome: 0,
    pillarSelectedInGov: 0,
    pillarNameSelectedInGov: "",
    principalSelectedGov: 0,
    pillarSelectedGovHis: 0,
    levelSelectedGov: "",
    reviewRequest: 0,
    prevSelectedRegion:0,
    validationReqFeedback: {},
    loggedInUserData: {},
    msalConfig: {
      auth: {
        clientId: "05e430e0-a018-47a8-9a1d-0faf9d186714",
        authority: "https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0"
      },
      cache: {
        cacheLocation: "sessionStorage"
      }
    },
    accessToken: ""
  },
  getters: {
    stateMSAL() {
      return {
        msalConfig: {
          auth: {
            clientId: "05e430e0-a018-47a8-9a1d-0faf9d186714",
            authority: "https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0"
          },
          cache: {
            cacheLocation: "sessionStorage"
          }
        },
        accessToken: ""
      };
    },
    menuItems: (state) => {
      return state.dashboardMenus;
    },
    selectedMenu: (state) => {
      return state.selectedMenu;
    },
    breadcrumb: (state) => {
      return state.breadcrumb;
    },
    oeeSelectedMonth: (state) => {
      return state.oeeSelectedMonth;
    },
    oeeSelectedYear: (state) => {
      return state.oeeSelectedYear;
    },
    plantSelectedInAsmtHome: (state) => {
      return state.plantSelectedInAsmtHome;
    },
    prevSelectedRegion: (state) => {
      return state.prevSelectedRegion;
    },
    prevSelectedWorkCenter: (state) => {
      return state.prevSelectedWorkCenter;
    },
    plantSelectedInTargetHome: (state) => {
      return state.plantSelectedInTargetHome;
    },
    pillarSelectedInGov: (state) => {
      return state.pillarSelectedInGov;
    },
    principalSelectedGov: (state) => {
      return state.principalSelectedGov;
    },
    pillarSelectedGovHis: (state) => {
      return state.pillarSelectedGovHis;
    },
    levelSelectedGov: (state) => {
      return state.levelSelectedGov;
    },
    pillarNameSelectedInGov: (state) => {
      return state.pillarNameSelectedInGov;
    },
    reviewRequest: (state) => {
      return state.reviewRequest;
    },
    validationReqFeedback: (state) => {
      return state.validationReqFeedback;
    },
    loggedInUserData: (state) => {
      return state.loggedInUserData;
    },
    accessToken: (state) => {
      return state.accessToken;
    },
    pillarPrevSelected: (state) => {
      return state.pillarPrevSelected;
    }
  },
  actions: {
    menuItems(context, menuItem) {
      context.commit("menuItems", menuItem);
    },
    selectedMenu(context, selectedMenuData) {
      context.commit("selectedMenu", selectedMenuData);
    },
    breadcrumb(context, breadcrumb) {
      context.commit("breadcrumb", breadcrumb);
    },
    oeeSelectedMonth: (context, oeeSelectedMonth) => {
      context.commit("oeeSelectedMonth", oeeSelectedMonth);
    },
    oeeSelectedYear: (context, oeeSelectedYear) => {
      context.commit("oeeSelectedYear", oeeSelectedYear);
    },
    plantSelectedInAsmtHome(context, plantSelectedInAsmtHome) {
      context.commit("plantSelectedInAsmtHome", plantSelectedInAsmtHome);
    },
    prevSelectedRegion(context, prevSelectedRegion) {
      context.commit("prevSelectedRegion", prevSelectedRegion);
    },
    prevSelectedWorkCenter(context, prevSelectedWorkCenter) {
      context.commit("prevSelectedWorkCenter", prevSelectedWorkCenter);
    },
    plantSelectedInTargetHome(context, plantSelectedInTargetHome) {
      context.commit("plantSelectedInTargetHome", plantSelectedInTargetHome);
    },
    pillarSelectedInGov(context, pillarSelectedInGov) {
      context.commit("pillarSelectedInGov", pillarSelectedInGov);
    },
    principalSelectedGov(context, principalSelectedGov) {
      context.commit("principalSelectedGov", principalSelectedGov);
    },
    pillarSelectedGovHis(context, pillarSelectedGovHis) {
      context.commit("pillarSelectedGovHis", pillarSelectedGovHis);
    },
    levelSelectedGov(context, levelSelectedGov) {
      context.commit("levelSelectedGov", levelSelectedGov);
    },
    pillarNameSelectedInGov(context, pillarNameSelectedInGov) {
      context.commit("pillarNameSelectedInGov", pillarNameSelectedInGov);
    },
    reviewRequest(context, reviewRequest) {
      context.commit("reviewRequest", reviewRequest);
    },
    validationReqFeedback(context, validationReqFeedback) {
      context.commit("validationReqFeedback", validationReqFeedback);
    },
    loggedInUserData(context, loggedInUserData) {
      context.commit("loggedInUserData", loggedInUserData);
    },
    accessToken(context, accessToken) {
      context.commit("accessToken", accessToken);
    },
    pillarPrevSelected(context, pillarPrevSelected) {
      context.commit("pillarPrevSelected", pillarPrevSelected);
    }
  },
  mutations: {
    menuItems(state, menuItem) {
      state.dashboardMenus = menuItem;
    },
    selectedMenu(state, selectedMenuData) {
      state.selectedMenu = selectedMenuData;
    },
    breadcrumb(state, breadcrumb) {
      state.breadcrumb = breadcrumb;
    },
    oeeSelectedMonth(state, oeeSelectedMonth) {
      state.oeeSelectedMonth = oeeSelectedMonth;
    },
    oeeSelectedYear(state, oeeSelectedYear) {
      state.oeeSelectedYear = oeeSelectedYear;
    },
    plantSelectedInAsmtHome(state, plantSelectedInAsmtHome) {
      state.plantSelectedInAsmtHome = plantSelectedInAsmtHome;
    },
    prevSelectedRegion(state, prevSelectedRegion) {
      state.prevSelectedRegion = prevSelectedRegion;
    },
    prevSelectedWorkCenter(state, prevSelectedWorkCenter) {
      state.prevSelectedWorkCenter = prevSelectedWorkCenter;
    },
    plantSelectedInTargetHome(state, plantSelectedInTargetHome) {
      state.plantSelectedInTargetHome = plantSelectedInTargetHome;
    },
    pillarSelectedInGov(state, pillarSelectedInGov) {
      state.pillarSelectedInGov = pillarSelectedInGov;
    },
    principalSelectedGov(state, principalSelectedGov) {
      state.principalSelectedGov = principalSelectedGov;
    },
    pillarSelectedGovHis(state, pillarSelectedGovHis) {
      state.pillarSelectedGovHis = pillarSelectedGovHis;
    },
    levelSelectedGov(state, levelSelectedGov) {
      state.levelSelectedGov = levelSelectedGov;
    },
    pillarNameSelectedInGov(state, pillarNameSelectedInGov) {
      state.pillarNameSelectedInGov = pillarNameSelectedInGov;
    },
    reviewRequest(state, reviewRequest) {
      state.reviewRequest = reviewRequest;
    },
    validationReqFeedback(state, validationReqFeedback) {
      state.validationReqFeedback = validationReqFeedback;
    },
    loggedInUserData(state, loggedInUserData) {
      state.loggedInUserData = loggedInUserData;
    },
    accessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    pillarPrevSelected(state, pillarPrevSelected) {
      state.pillarPrevSelected = pillarPrevSelected;
    }
  }
});
export default store;
