import Vue from "vue";
import App from "./App.vue";
import Emitter from "tiny-emitter";
import store from "./store/store";
import router from "./router/index.js";
import "./common/css/CommonStyle.css";
import "./common/css/font-categories.css";
import "./plugins/bootstrap-vue";
import "@babel/polyfill";
import { IconsPlugin } from "bootstrap-vue";
import "../src/common/filters/filters";
import { MonthPickerInput } from "vue-month-picker";

Vue.use(MonthPickerInput);
Vue.use(IconsPlugin);
Vue.prototype.$msalInstance = {};
Vue.prototype.$oidc = Vue.observable({});
Vue.prototype.$store = store;
Vue.prototype.$emitter = new Emitter();
const app = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
