const CORPORATE = "po_corporate";
const GLOBALPILLARLEADER = "po_global_pillarleader";
const REGIONALPOLEADER = "po_regional_poleader";
const REGIONALPILLARLEADER = "po_regional_pillarleader";
const PLANTMANAGER = "po_plant_manager";
const PLANTPOLEADER = "po_plant_poleader";
const PLANTPILLARLEADER = "po_plant_pillarleader";
const PLANTSUBPILLARLEADER = "po_plant_subpillarleader";
const USER = "po_user";
const CODELELVEADMIN = "po_code_admin";
const SYSTEMLEVELADMIN = "po_system_admin";
const FUNCTIONALADMIN = "po_functional_admin";
const INDUSTRYENGINEER = "po_global_industry_engineer";
const POENERGYADMIN = "po_energy_admin";
const POENERGYUSER = "po_energy_user";

const CORPORATENAME = "Corporate";
const GLOBALPILLARLEADERNAME = "Global Pillar Leader";
const REGIONALPOLEADERNAME = "Regional PO Leader";
const REGIONALPILLARLEADERNAME = "Regional Pillar Leader";
const PLANTMANAGERNAME = "Plant Manager";
const PLANTPOLEADERNAME = "Plant PO Leader";
const PLANTPILLARLEADERNAME = "Plant Pillar Leader";
const PLANTSUBPILLARLEADERNAME = "Plant Subpillar Leader";
const USERNAME = "Associate";
const USERNAMEROLE = "User";
const SYSTEMLEVELADMINNAME = "System Admin";
const CODEADMINNAME = "Code Admin";
const FUNCTIONALADMINNAME = "Functional Admin";
const INDUSTRYENGINEERNAME = "Global Industry Engineer";

const POENERGYADMINNAME = "Energy Admin";
const POENERGYUSERNAME = "Energy User";

export default {
  CORPORATE: CORPORATE,
  GLOBALPILLARLEADER: GLOBALPILLARLEADER,
  REGIONALPOLEADER: REGIONALPOLEADER,
  REGIONALPILLARLEADER: REGIONALPILLARLEADER,
  PLANTMANAGER: PLANTMANAGER,
  PLANTPOLEADER: PLANTPOLEADER,
  PLANTPILLARLEADER: PLANTPILLARLEADER,
  PLANTSUBPILLARLEADER: PLANTSUBPILLARLEADER,
  INDUSTRYENGINEER: INDUSTRYENGINEER,
  USER: USER,
  CODELELVEADMIN: CODELELVEADMIN,
  SYSTEMLEVELADMIN: SYSTEMLEVELADMIN,
  FUNCTIONALADMIN: FUNCTIONALADMIN,
  CORPORATENAME: CORPORATENAME,
  GLOBALPILLARLEADERNAME: GLOBALPILLARLEADERNAME,
  REGIONALPOLEADERNAME: REGIONALPOLEADERNAME,
  REGIONALPILLARLEADERNAME: REGIONALPILLARLEADERNAME,
  PLANTMANAGERNAME: PLANTMANAGERNAME,
  PLANTPOLEADERNAME: PLANTPOLEADERNAME,
  PLANTPILLARLEADERNAME: PLANTPILLARLEADERNAME,
  PLANTSUBPILLARLEADERNAME: PLANTSUBPILLARLEADERNAME,
  USERNAME: USERNAME,
  USERNAMEROLE: USERNAMEROLE,
  SYSTEMLEVELADMINNAME: SYSTEMLEVELADMINNAME,
  CODEADMINNAME: CODEADMINNAME,
  FUNCTIONALADMINNAME: FUNCTIONALADMINNAME,
  INDUSTRYENGINEERNAME: INDUSTRYENGINEERNAME,
  POENERGYUSER: POENERGYUSER,
  POENERGYADMIN: POENERGYADMIN,
  POENERGYADMINNAME: POENERGYADMINNAME,
  POENERGYUSERNAME: POENERGYUSERNAME
};
